<div #input class="form-control" [ngClass]="{invalid: control?.invalid && (!messages.length) || !!errors?.length, touched: control?.touched && (!messages.length), filled: control?.value || control?.value == 0}">
  <div class="form-control__input">
    <ng-content></ng-content>
    @if (label) {
      <span class="form-control__label" [ngClass]="{smaller: isTopLabelDefault}">{{ label }}</span>
    }
  </div>
  <div class="form-control__error-line"></div>
  @if (!control || (control && control.touched) || (control && !control.pristine && validationMessages.length)) {
    @if (errors && errors.length && (!messages.length)) {
      <div class="form-control__error" >
        @for (error of errors; track i; let last = $last; let i = $index) {
          <span
            >{{ (!error?.error ? error : error.error) | translate : error.attributes }}{{
          error.length > 1 && !last ? ',' : '' }} </span>
        }
      </div>
    }
    @if (messages.length || (validationMessages.length && control.valid)) {
      @for (message of (messages.length ? messages : validationMessages); track message) {
        <div class="form-control__validation-message">
          @if (control.invalid) {
            <i class="icon-ellipse"></i>
          }
          @if (control.valid) {
            <i class="icon-check-validation"></i>
          }
          <span>{{ message | translate }}</span>
        </div>
      }
    }

    @if (bottomPlaceholder) {
      <div class="form-control__placeholder">{{ bottomPlaceholder }}</div>
    }
  }
</div>
